@media only screen and (max-width: 1300px) {
	.product-image {
  	width: 400px;
  }
}

@media only screen and (max-width: 1100px) {
	
	nav a {
		margin-right: 0;
		margin-left: 20px;
		font-size: .9rem;
	}

	.hero {
		padding-top: 20px;
	}

	.hero h2 {
		font-size: 1.25rem;
	}
	
	.hero img {
		width: 50%;
	}

	.stage {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}

	form {
		width: 100%;
	}

	form input {
		max-width: 75%;
	}

	form h2 {
		font-size: 1.25rem;
		margin-bottom: 30px;
	}

	.metric-number {
		width: 75px;
		height: 75px;
	}

	.profile-links a {
		margin-right: 0;
		margin-left: 10px;
	}

	.logout {
		margin-left: 10px;
	}

	form #description, form #bio {
		max-width: 75%;
	}

	.stats-wrapper {
	  grid-template-columns: repeat(3, 1fr);
	}

	.movement-chart a.active,
	.movement-chart a:hover {
		border-bottom: none;
	}

	.product-points {
    flex-direction: column;
  }

  .product-point {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .product-point:nth-child(2) {
    flex-direction: column;
  }

  .explanation {
  	width: 75%;
  	font-size: 1rem;
  }

  .cta-copy {
  	width: 100%;
  	margin: 0 auto;
  	margin-top: 20px;
  }

  .fitness-metric-icons img {
  	width: 50px; /* Set the image width to 50px */
    height: auto;
  }

  .metrics {
  	margin-top: 30px;
  	margin-bottom: 10px;
  }
}


@media only screen and (max-width: 750px) {
	.stats-wrapper {
	  grid-template-columns: repeat(2, 1fr);
	}

	.movement-chart a {
		display: block;
	}

	.movement-chart a.active,
	.movement-chart a:hover {
		border-bottom: none;
	}

	.product-point p,
	.product-point h3 {
		padding-left: 0px;
		padding-right: 0px;
	}

	.explanation {
		width: 100%;
		font-size: .9rem;
	}

	.profile-links {
		margin-top: 20px;
		font-size: .85rem;
		margin-right: 0;
	}

	.profile-links a {
		margin-top: 30px;
		padding: 0;
	}

	.profile-links a.active {
		background: transparent;
		color: #fb923c;
		border-bottom: 1px solid #fb923c;
		border-radius: 0;
	}

	.metrics {
  	margin-top: 30px;
  	margin-bottom: 10px;
  }

  .big-name {
  	margin-bottom: 0;
  	line-height: 2.25rem;
  }

  .profile-pic {
  	width: 75px;
  	height: 75px;
  	margin-top: 20px;
  	margin-bottom: 10px;
  }

  .filter-tests {
  	width: 80%;
  }

}

@media only screen and (max-width: 500px) {
	.metric-number {
		width: 50px;
		height: 50px;
		font-size: 1rem;
	}

	nav a {
		margin-right: 0;
		margin-left: 10px;
		font-size: .75rem;
	}


	.profile-links {
		margin-top: 20px;
		font-size: .85rem;
		margin-right: 0;
	}

	.profile-links a {
		margin-top: 30px;
		padding: 0;
	}

	.profile-links a.active {
		background: transparent;
		color: #fb923c;
		border-bottom: 1px solid #fb923c;
		border-radius: 0;
	}

	.movement-chart a {
		display: block;
	}

	.movement-chart a.active,
	.movement-chart a:hover {
		border-bottom: none;
	}

	.stats .bio {
		display: none;
	}

	.stats-wrapper {
    display: block;
  }
	  
  .stat {
    margin-bottom: 20px;
  }

  .hero h2 {
  	font-size: 1rem;
  }

  .hero img {
  	width: 100%;
  }

  .logo {
  	width: 75% !important;
  	margin-top: 50px;
  }

  .fitness-metric-icons {
  	width: 100%;
  }

  .fitness-metric-icons img {
  	width: 50px;
  }

  .metrics {
  	margin-top: 20px;
  	margin-bottom: 10px;
  }

  .following .bio {
  	display: none;
  }

  .filter-tests {
  	width: 80%;
  }

  .update-form {
  	width: 90%;
  }

  .update-form label {
  	display: inline-block;
  }

  .update-form label input {
  	margin-bottom: 0;
  }

  .update-form .description-label {
  	width: 100%;
  	margin-right: 10%;
  }

  .update-form .date-label {
  	width: 100%;
  }

  .post-energy,
  .post-mood,
  .post-sleep {
  	display: inline-block;
  	background-color: #020617;
  	padding: 5px 10px;
  	border-radius: 7px;
  	margin-right: 5px;
  	font-size: .75rem;
  }

  input#mood,
  input#energy,
  input#hours_of_sleep {
  	width: 100%;
  	font-size: .8rem;
  }

}