.stats {
	padding-bottom: 100px;
}

.stats-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px; /* Adjust the gutter size as needed */
  margin-top: 20px;
}

.stat {
  flex: 1 0 33.33%;
  box-sizing: border-box;
  padding: 20px; /* Add some padding to space the columns */
  border-radius: .5rem;
  background: #0f172a;
  box-shadow: 0 0 60px 10px rgb(2, 6, 23, .5);
}

.stat .number {
	font-size: 4rem;
	font-weight: 600;
	display: inline-block;
	color: #a78bfa;
}

.stat span {
	margin-right: 5px;
}

.stat-type-sun .label,
.stat-type-sun .number,
.stat-type-sun .unit {
	color: #fde047;
}

.stat-type-cold .label,
.stat-type-cold .number,
.stat-type-cold .unit {
	color: #38bdf8;
}

.stat-type-sauna .label,
.stat-type-sauna .number,
.stat-type-sauna .unit {
	color: #f59e0b;
}

.stat-type-workout .label,
.stat-type-workout .number,
.stat-type-workout .unit {
	color: #34d399;
}

.stat-type-test .label,
.stat-type-test .number,
.stat-type-test .unit {
	color: #94a3b8;
}

.stat-category {
	display: block;
	font-size: .85rem;
}

.stat-type-test .stat-type {
	display: none;
}

.stat-type-test .stat-movement {
	color: white;
}

.stat-result-unit {
	font-size: 1rem;
}

.unit {
	text-transform: uppercase;
	font-size: .85rem;
}

h3.time-period,
h3.section-heading {
	margin-bottom: 5px;
	margin-top: 0;
	font-weight: 400;
}

h3.section-heading {
	margin-top: 40px;
	color: #fb923c;
	margin-bottom: 3px;
}

h4.section-info {
	font-size: 1rem;
	color: #94a3b8;
	font-weight: 300;
	margin-top: 0;
	margin-bottom: 0;
}

.stats-nav {
	padding: 20px;
	border-radius: .5rem;
	background: #0f172a;
	box-shadow: 0 0 60px 10px rgb(2, 6, 23, .5);
	padding-bottom: 25px;
	margin-top: 20px;
}