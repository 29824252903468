body {
	background: #0f172a;
	color: white;
	font-family: Poppins;
	padding: 20px;
	background: rgb(31,41,55);
	background: linear-gradient(307deg, rgba(31,41,55,1) 6%, rgba(16,24,40,1) 35%);
	background-attachment: fixed; 
}

nav {
	width: 100%;
	display: block;
	text-align: right;
}

nav a {
	color: #34d399;
	margin-right: 20px;
	text-decoration: none;
	font-size: 1rem;
}

nav a:hover {
	color: #6ee7b7;
}

nav img {
	margin-right: 20px;
	float: left;
}

.stage {
	width: 960px;
	margin: auto;
}

.logo {
	width: 600px;
	margin-top: 20px;
}

h1.title {
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  visibility: hidden;
}

h1.big-name {
	margin: 0;
	padding: 0;
}

.small-name {
	font-size: 1.25rem;
	color: #94a3b8;
	margin: 0;
	margin-bottom: 0px;
}

h2 {
	font-weight: 300;
	color: #94a3b8;
}

h2.subhead-alt {
	color: #fb923c;
	font-size: 1rem;
	font-weight: 600;
}

h2.bio {
	font-size: 1rem;
	margin: 0;
	padding: 0;
	margin-bottom: 20px;
}

.timeline-date {
	margin-bottom: 20px;
	font-size: 1rem;
	font-weight: 400;
	color: #cbd5e1;
	margin-top: 0;
}

.metrics {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 25px;
}

.metric-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 3px solid #6366f1;
  background-color: #020617;
  border-radius: 50%;
  font-size: 24px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 2rem;
  box-shadow: 0 0 60px 10px rgb(2, 6, 23, .9);
}

.metric-label {
  display: block;
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

.sun-metric {
	border-color: #fde047;
	color: #fde047;
}

.sun-metric-label {
	color: #fde047;
}

.workout-metric {
	border-color: #34d399;
	color: #34d399;
}

.workout-metric-label {
	color: #34d399;
}

.sauna-metric {
	border-color: #f59e0b;
	color: #f59e0b;
}

.sauna-metric-label {
	color: #f59e0b;
}

.cold-metric {
	border-color: #38bdf8;
	color: #38bdf8;
}

.cold-metric-label {
	color: #38bdf8;
}

.Test .post-content,
.Sun .post-content,
.Workout .post-content,
.Sauna .post-content,
.Cold  .post-content,
.Note .post-content,
.Checkin .post-content {
	font-family: monospace;
	border-left: dotted 2px pink;
	font-size: 1rem;
	padding-left: 20px;
	margin-bottom: 40px;
	margin-left: 70px;
	padding-left: 20px;
	margin-top: -80px;
}

ul { 
	list-style-type: none;
}

.Workout .post-content {
	border-color: #34d399;
	color: #34d399;
}

.Note .post-content {
	border-left: none;
}

.Test .post-content {
	border-color: #94a3b8;
	color: #94a3b8;
}

.Test {
	margin-top: 110px;
}

.Test .post-type {
	display: none;
}

h4.post-type	{
	font-size: 1.15rem;
	margin-bottom: 0;
}

.post-date {
	margin-top: 5px;
	margin-bottom: 5px;
}

.post-minutes {
	margin-top: 0;
	margin-bottom: 0;
}

.Test .post-minutes {
	display: none;
}

.post-movement {
	color: white;
	font-family: Poppins, sans-serif;
	font-size: 1.5rem;
	margin: 6px 0 0 0;
}

.Test .post-date {
	margin: 0;
}

.Test .post-description {
	display: none;
}

.Workout .post-description h1,
.Workout .post-description h2,
.Workout .post-description h3 {
	color: #34d399;
	font-size: 1rem;
	font-weight: 300;
}

.Workout .post-minutes {
	margin-top: 15px;
}

.Workout .post-description p,
.Workout .post-description li {
	font-size: .85rem;
	line-height: 1.45rem;
}

.Workout .post-description a {
	color: #34d399;
	text-decoration: underline;
}

.Workout .post-description ul {
	list-style-type: disc;
	margin-left: 20px;
}

.Note {
	margin-top: 60px;
}

.Note h4 {
	margin-top: 0px;
	font-family: monospace;
	font-size: 1.15rem;
}

.Note .post-content {
	padding: 20px;
	box-shadow: 0 0 60px 10px rgb(2, 6, 23, .5);
	border-radius: 10px;
	background-color: rgba(226, 232, 240, .03);
	font-family: Poppins, sans-serif;
	padding-bottom: 10px;
	overflow: visible;
}

.Note .post-minutes {
	display: none;
}

.Note .post-date {
	font-size: .75rem;
	font-family: monospace;
	color: #cbd5e1;
}

.Note .post-description {
	color: #cbd5e1;
}

.post-score-category {
	color: #34d399;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	margin: 0;
	margin-bottom: 20px;
	font-weight: 300;
}

.movement-chart {
	font-family: Poppins;
}

.movement-chart a {
	color: #64748b;
	text-decoration: none;
	margin-right: 20px;
	padding-bottom: 5px;
	cursor: pointer;
}

.movement-chart a:hover {
	border-bottom: 2px solid #64748b;
}

.movement-chart a.active {
	color: #fb923c;
	border-bottom: 2px solid #fb923c;
}

.movement-chart h2,
.timeline-title {
	font-size: 1.5rem;
	color: white;
	font-weight: 600;
	margin-bottom: 5px;
}

.fitness-tests .movement-chart {
	margin-bottom: 50px;
}

.fitness-tests .post-movement {
	font-weight: 600;
	font-size: 1.25rem;
}

.fitness-tests .bio {
	margin-bottom: 20px;
}

.filter-tests {
	margin-bottom: 30px;
	margin-top: 10px;
	padding: 10px 15px;
	border-radius: 2rem;
	width: 300px;
	border: none;
	box-shadow: 0 0 60px 10px rgb(2, 6, 23, .75);
}

.fitness-tests .movement-chart {
  transition: opacity 0.5s ease;
}

.fitness-tests .movement-chart.visible {
  opacity: 1;
}

.movement-chart.hidden {
  opacity: 0;
}


.Cold .post-content {
	border-color: #38bdf8;
	color: #38bdf8;
}

.Sun .post-content {
	border-color: #fde047;
	color: #fde047;
}

.Sauna .post-content {
	border-color: #f59e0b;
	color: #f59e0b;
}

.Checkin .post-content {
	border-color: #a78bfa;
	color: #a78bfa;
}

.post-energy,
.post-mood,
.post-sleep {
	display: inline-block;
	background-color: #020617;
	padding: 10px 20px;
	border-radius: 7px;
	margin-right: 20px;
	box-shadow: 0 0 40px 10px rgb(2, 6, 23, .75);
	font-family: Poppins, sans-serif;
	font-size: .9rem;
}

input#mood,
input#energy,
input#hours_of_sleep {
	width: 65%;
}

.good {
	color: #a3e635;
}

.meh {
	color: #fb923c;
}

.bad {
	color: #ef4444;
}

.hero {
	text-align: center;
	width: 100%;
	padding-top: 80px;
	padding-bottom: 100px;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
}

h2.tagline {
	margin: 0;
	color: #fb923c;
}

.product-points {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
}

.product-point {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  max-width: 80%;
  text-align: left	;
}

.product-point p,
.product-point h3 {
	padding-left: 40px;
	padding-right: 40px;
}

.product-point h3 {
	color: #fb923c;	
	font-weight: 300;
	font-size: 1.25rem;
}

.product-image {
  width: 600px;
  height: auto;
  margin: 0 20px;
}

.product-point:nth-child(2) {
  flex-direction: row-reverse;
}

.product-image {
	border-radius: .75rem;
  box-shadow: 0 0 60px 10px rgb(2, 6, 23, .5);
}

.fitness-metric-icons {
	width: 400px;
	display: grid;
  display: flex;
  justify-content: space-around;
  margin: 0 auto; 
  margin-top: 60px;
}

.explanation {
	width: 800px;
	margin: 10px auto 20px auto;
	display: flex;
}

.cta-copy {
	width: 600px;
	margin: 0 auto;
	margin-top: 20px;
}

.fitness-metric-icons img {
	width: 50px; /* Set the image width to 50px */
  height: auto;
}


.why-wrapper {
	padding: 20px;
	max-width: 800px;
	margin: auto;
}

.why-wrapper h2 {
	color: #fb923c;
	font-weight: 600;
	font-size: 1.5rem;
	margin-top: 40px;
	margin-bottom: 40px;
}

.why-wrapper p {
	color: white;
	font-size: 1.25rem;
	font-weight: 300;
}

.view-live-profile {
	color: #34d399;
	text-decoration: none;
	font-size: 1rem;
	display: block;
}

.view-live-profile:hover {
	color: #6ee7b7;
}

.post-icon {
	display: inline-block; 
  width: 50px;
  height: 50px;
  background-repeat: no-repeat; 
  background-position: center; 
  background-size: contain; 
}

.Workout .post-icon {
  background-image: url('./img/workout.svg'); 
}

.Sun .post-icon {
  background-image: url('./img/sun.svg'); 
}

.Sauna .post-icon {
  background-image: url('./img/sauna.svg'); 
}

.Note .post-icon {
	background-image: url('./img/note.svg');
}

.Cold .post-icon {
  background-image: url('./img/cold.svg'); 
}

.Checkin .post-icon {
  background-image: url('./img/checkin.svg'); 
}

.Test .post-icon {
  background-image: url('./img/test.svg'); 
  float: left;
  margin-top: 0;
}

.btn-primary {
	background-color: #34d399;
	color: white;
	padding: 20px 30px;
	text-align: center;
	margin: 20px auto;
	display: inline-block;
	border-radius: .25rem;
	box-shadow:5px 5px 0px #fb923c;
	text-decoration: none;
	font-weight: 500;
	border: none;
	font-size: 1rem;
}

.btn-secondary {
	background-color: #fb923c;
	color: white;
	padding: 20px 30px;
	text-align: center;
	margin: 20px auto;
	display: inline-block;
	border-radius: .25rem;
	box-shadow:5px 5px 0px #34d399;
	text-decoration: none;
	font-weight: 500;
	border: none;
	font-size: 1rem;
}

.btn-primary:hover {
	background-color: #6ee7b7;
	box-shadow: 2px 2px 0px #fb923c;
	text-decoration: none;
	border: none;
	cursor: pointer;
}

.btn-secondary:hover {
	background-color: #fdba74;
	box-shadow: 2px 2px 0px #34d399;
	text-decoration: none;
	border: none;
	cursor: pointer;
}

.logout {
	padding: 10px;
	background: #1e293b;
	color: #64748b;
	border: none;
	border-radius: .25rem;
}

.logout:hover {
	background-color: #991b1b;
	color: whitesmoke;
	cursor: pointer;
}

.post-entry {
	background: #0f172a;
	padding: 20px;
	border-radius: 1rem;
	border: 2px solid #34d399;
	box-shadow: 0 0 60px 10px rgb(2, 6, 23, .8);
	position: relative;
}

.post-toggle {
	background: #34d399;
	border: none;
	border-radius: .5rem;
	padding: 10px 20px;
	color: white;
	text-align: center;
	margin: 20px auto;
	display: inline-block;
	border-radius: .25rem;
	box-shadow:5px 5px 0px #115e59;
	text-decoration: none;
	font-weight: 500;
	border: none;
	font-size: 1rem;
	cursor: pointer;
}

.post-toggle:hover {
	background: #6ee7b7;
	box-shadow:3px 3px 0px #115e59;
}

.post-hide {
  background-color: #64748b;
}

.post-hide:hover {
  background-color: #334155;
}

form {
	width: 600px;
}

form label,
form input {
	display: block;
}

input:focus {
	outline-color: #fb923c;
}

form label {
	margin-bottom: 10px;
}

form input {
	margin-bottom: 30px;
	padding: 20px;
	display: block;
	width: 100%;
	border-radius: .5rem;
	font-size: 1rem;
	color: #0f172a;
}

form #date {
	width: 200px;
}

form #description,
form #bio {
	width: 100%;
	height: 200px;
	border-radius: .5rem;
	margin-bottom: 20px;
	padding: 20px;
	font-family: Poppins, sans-serif;
}

form #minutes,
form #score {
	width: 100px;
}

form select {
	margin-bottom: 30px;
	padding: 20px;
	display: block;
	width: 100%;
	border-radius: .5rem;
	font-size: 1rem;
	color: #0f172a;
}

.save,
.cancel,
.not-editing {
	float: left;
	margin-right: 20px;
	border: none;
	padding: 20px 40px;
	border-radius: .25rem;
	cursor: pointer;
}

.save {
	color: white;
	background-color: #34d399;
}

.save:hover {
	background-color: #6ee7b7;
}

.cancel {
	color: white;
	background-color: #64748b;
}

.cancel:hover {
	background-color: #94a3b8;
}

.not-editing {
	background-color: #4b5563;
	margin-top: 20px;
}

.not-editing:hover {
	background-color: #6b7280;
}

#profile_pic {
	background-color: #6b7280;
	margin-top: 20px;
}

.settings {
	padding-bottom: 100px;
}

.settings h4 {
	font-size: 1.5rem;
	margin: 0;
	font-weight: 400;
}

.settings h3 {
	margin: 0;
	margin-top: 20px;
	font-size: 1rem;
	font-weight: 300;
	color: #fb923c;
}

ul {
	margin: 0;
	padding: 0;
}

.profile-pic {
	border-radius: 100%;
	width: 100px;
	height: 100px;
	border: 4px solid #34d399;
	box-shadow: 0 0 60px 10px rgb(2, 6, 23, 1);
}

.profile-links {
	margin-top: 80px;
}

.profile-links a {
	float: right;
	padding: 10px 20px;
	color: #94a3b8;
	border-radius: .25rem;
	text-decoration: none;
	margin-left: 10px;
}

.profile-links a.active,
.profile-links a:hover {
	background-color: transparent;
	color: #cbd5e1;
	cursor: default;
	background-color: #1e293b;
	box-shadow: 0 0 60px 10px rgb(2, 6, 23, .55);
}

.profile-links a.inactive:hover {
	cursor: pointer;
	background-color: #1e293b;
}


.delete-post {
	float: right;	
	background-color: transparent;
	border: none;
	color: #94a3b8;
	display: none;
	cursor: pointer;
	font-size: .75rem;
	padding: 5px 10px;
	border-radius: .25rem;
	text-transform: uppercase;
	letter-spacing: .1rem;
	font-family: monospace;
}

.delete-post:hover {
	color: white;
	background-color: #991b1b;
}

.post-content:hover .delete-post {
	display: inline-block;
}


.no-metrics,
.no-posts,
.no-fitness-tests {
	padding: 40px;
	background-color: #020617;
	border: 2px solid #1e293b;
	margin-bottom: 20px;
	border-radius: .5rem;
	font-family: monospace;
	font-size: 1.15rem;
	color: #64748b;
	box-shadow: 0 0 30px rgb(2, 6, 23, .6);
}

.no-metrics {
	margin-top: 30px;
}

.forgot-password {
	text-decoration: none;
	color: white;
	font-size: 1rem;
	color: #34d399;
}

.instructions {
	font-size: 1rem;
	color: #0ea5e9;
}

.markdown-link {
	color: #64748b;
}

.label-more {
	font-size: .85rem;
	color: #64748b;
}

.loading-alert {
	background-color: #020617;
	position: fixed;
  bottom: 40px;
  padding: 15px 30px;
  border-radius: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  box-shadow: 0 0 20px rgba(45, 212, 191, .4);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 20px rgba(45, 212, 191, .2);
  }
  25% {
    box-shadow: 0 0 20px rgba(45, 212, 191, .4);
  }
  50% {
    box-shadow: 0 0 20px rgba(45, 212, 191, .6);
  }
  75% {
    box-shadow: 0 0 20px rgba(45, 212, 191, .4);
  }
  100% {
    box-shadow: 0 0 20px rgba(45, 212, 191, .2);
  }
}

.dashboard {
	padding-bottom: 200px;
}

.dashboard h2 {
	font-size: 1rem;
	font-weight: 400;
	margin-top: 40px;
	color: #fb923c; 
}

.dashboard a {
	color: white;
	text-decoration: none;
}

.dashboard li {
	margin-bottom: 10px;
	color: #94a3b8;
}

.no-more-posts {
	background-color: #020617;
	padding: 20px;
	border-radius: .5rem;
}

.stream .user-wrapper-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  text-decoration: none;
}

.stream .user-wrapper-inner img {
	width: 30px;
	height: 30px;
	border-radius: 100%;
	background-color: #6b7280;
}

.stream .user-wrapper-inner .post-user {
	color: #cbd5e1;
	font-family: Poppins;
	font-size: .9rem;
	text-decoration: none;
}

.user-wrapper {
	text-decoration: none;
}

.user-wrapper-inner img {
  transition: transform 0.3s ease-in-out;
}

.user-wrapper-inner:hover img {
  transform: scale(1.03);
}


.user-wrapper-inner:hover .post-user {
  color: #e2e8f0;
}

.following-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #94a3b8;
  margin: 10px auto 20px auto;
}

.following-wrapper a {
	color: #94a3b8;
	text-decoration: none;
	font-size: .9rem;
	border-bottom: 2px solid transparent;
}

.following-wrapper a:hover {
	color: #fb923c;
	border-bottom: 2px solid #fb923c;
}

.class-for-follow,
.class-for-unfollow {
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	background-color: #34d399;
	box-shadow: 5px 5px 0px #fb923c;
	color: white;
	margin-bottom: 10px;
}

.class-for-follow:hover,
.class-for-unfollow:hover {
	cursor: pointer;
}

.class-for-unfollow {
	background-color: #64748b;
}

.class-for-unfollow:hover {
	box-shadow: 3px 3px 0px #fb923c;
}

.following-user img {
	width: 60px;
	height: 60px;
	border-width: 2px;
	margin-right: 10px;
}

.following-user h2 {
	color: white;
	font-size: 1rem;
	font-weight: 600;
	margin: 0;
	padding: 0;
}

.following-user a {
	text-decoration: none;
	color: #94a3b8;
}

.following-user .username p {
	margin: 0;
	font-size: .85rem;
}

.following h1 {
	margin-bottom: 40px;
}

.following .unfollow {
	float: right;
}

.following-user {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-decoration: none;
	color: #94a3b8;
	margin-bottom: 40px;
}

.following .left-items {
	display: flex;
	gap: 10px;
	align-items: center;
}

.following .bio {
	padding-right: 20px;
}

.following .small-name,
.following .big-name {
	margin-bottom: 40px;
}

.following h1 a {
	color: white;
	text-decoration: none;
}

.following h1 a:hover {
	color: #fb923c;
}

.following h1 span {
	color: #94a3b8;
	font-size: 1.5rem;
	font-weight: 400;
}


.fitness-tests .bio,
.stats .bio,
.notes .bio {
	margin-top: 20px;
}

.notes h3 {
	margin-top: 40px;
}

.bio a {
	color: white;
	text-decoration: none;
}

.bio a:hover,
.settings .bio a:hover {
	color: #fb923c;
	border-bottom: 1px solid #fb923c;
	padding-bottom: 5px;
}

.settings .bio a {
	border-bottom: 1px solid white;
	padding-bottom: 5px;
}

.post-show-x {
	display: none;
}

.post-hide-x {
	position: absolute;
	right: -5px;
	top: -20px;
	background-color: transparent;
	box-shadow: none;
	color: #34d399;
	font-family: sans-serif;
	font-size: 1.5rem;
}

.post-hide-x:hover {
	position: absolute;
	right: -5px;
	top: -20px;
	background-color: transparent;
	box-shadow: none;
	color: #99f6e4;
	font-family: sans-serif;
}

.filter-container {
  position: relative;
}

.filter-tests {
  padding-right: 20px; /* add space for the "X" */
}

.filter-tests:focus {
  outline: none;
  box-shadow: 0 0 5px 10px rgba(45, 212, 191, .25);
}

.clear-filter {
  margin-left: -45px;
  cursor: pointer;
  font-size: 12px;
  padding: 20px;
  color: lightgray; /* Change this to your preferred color */
}

.updates h1 {
	margin-bottom: 40px;
	font-family: Poppins, sans-serif;
	margin-top: 60px;
}

.update {
	padding: 20px;
	margin-bottom: 30px;
	box-shadow: 0 0 60px 10px rgb(2, 6, 23, .9);
	border-radius: 10px;
	font-family: monospace;
	font-size: 1rem;
}

.update p {
	margin: 0;
	padding: 0;
	color: #94a3b8;
}

.update .date {
	margin-bottom: 10px;
	color: #34d399;
}

.update-form {
	width: 95%;
	position: relative;
}

.update-form label,
.update-form select {
	display: inline-block;
	margin-bottom: 0;
}

.update-form label input {
	margin-bottom: 0px;
}

.update-form .description-label {
	width: 100%;
	margin-right: 10%;
}

.update-form .date-label {
	width: 33%;
}

.update-form .status-label {
	width: 33%;
}

.update-form .type-label {
	width: 34%;
}

.update-form .submit-button {
	background-color: #34d399;
	border: none;
	border-radius: 5px;
	margin-bottom: 40px;
	padding: 10px 20px;
	font-family: Poppins, sans-serif;
	font-weight: 600;
	margin-left: 2px;
	margin-top: 20px;
	color: white;
	box-shadow:5px 5px 0px #fb923c;
}

.update-form .submit-button:hover {
	box-shadow:1px 1px 0px #fb923c;
	cursor: pointer;
}

.update-delete {
	position: absolute;
	right: 20px;
	background-color: transparent;
	border: none;
	top: 20px;
	border-radius: 5px;
	color: #450a0a;
	display: none;
}

.update-delete:hover {
	cursor: pointer;
	color: #b91c1c;
}

.update:hover .update-delete {
	display: inline-block;
}

.updates .issue,
.updates .fix, 
.updates .feature {
	display: flex;
  align-items: center; 
  position: relative;
}

.updates .issue .update-icon,
.updates .fix .update-icon, 
.updates .feature .update-icon {
	width: 50px;
	height: 50px;
	margin-right: 20px;
	display: inline-block; 
  width: 50px;
  height: 50px;
  background-repeat: no-repeat; 
  background-position: center; 
  background-size: contain; 
  flex-shrink: 0; 
}

.issue .date,
.issue span {
	color: #FB923C !important;
}

.fix .date,
.fix span {
	color: #34D399 !important;
}

.feature .date,
.feature span {
	color: #38BDF8 !important;
}

.issue .update-icon {
  background-image: url('./img/alert.svg'); 
}

.fix .update-icon {
  background-image: url('./img/fix.svg'); 
}

.feature .update-icon {
  background-image: url('./img/feature.svg'); 
}

.update-status {
	font-family: Poppins, sans-serif;
	font-size: .75rem;
	background-color: #020617;
	color: #334155;
	border-radius: 5px;
	padding: 5px 10px;
	text-align: center;
	display: inline-block;
	margin-top: 10px;
}

.in-progress {
	background-color: #0c4a6e;
	color: #020617;
}

.complete {
	background-color: #134e4a;
	color: #020617;
}

.backlog {
	background-color: #334155;
	color: #020617;
}

.updates .filters select {
	background-color: transparent;
	color: #94a3b8;
	border-radius: 7px;
	margin-bottom: 30px;
	margin-right: 20px;
	border: none;
}

.updates .update-status-select {
	position: absolute;
	right: 80px;
	background-color: transparent;
	border: none;
	top: 20px;
	border-radius: 5px;
	color: #94a3b8;
}

.post-show-page {
	margin-top: 100px;
	position: relative;
}

.post-show-page .post-icon  {
	margin-left: 30px;
}

.post-show-page .post-content {
	margin-left: 100px;
}



.custom-file-upload {  
  color: #f97316;
  cursor: pointer;
  display: inline-block;
  border-bottom: 2px solid transparent;
  margin-bottom: 25px;
}
.custom-file-upload:hover {
  border-bottom: 2px solid #f97316;
}

.post-photo-preview {
	border-radius: 20px;
	display: block;
	margin-bottom: 30px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the overlay is above all other elements */
}

.workout-with-photo {
	border-radius: 20px;
	padding: 20px;
	margin-bottom: 40px;
	padding-top: 55px;
	box-shadow: 0 0 60px 10px rgb(2, 6, 23, .5);
}

.workout-with-photo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #111827;
  opacity: 0.75;
  z-index: 1;
  border-radius: 20px;
}

.no-posts a {
	color: white;
}

.post-show-page .by-user {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-family: Poppins, sans-serif;
  margin-top: 10px;
}

.post-show-page .by-user img {
	width: 30px;
	height: 30px;
	border-radius: 100%;
	margin-right: 8px;
}

.post-show-page .by-user:hover {
	color: #f59e0b;
}

