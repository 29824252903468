.success-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(15, 23, 42, .75);
  z-index: 999;
  color: white;
  font-size: 4rem;
  font-weight: 600;
}
